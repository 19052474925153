import React from "react"
import { connectToXeroPage } from "../../Content/FeatureInnerPages"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FeatureDetailsPage from "../../components/pages/FeatureDetails/FeatureDetailsPage"

const ConnectToXero = () => {
  return (
    <Layout>
      <Seo
        title="Seamlessly Sync Client Data with Synkli and Xero Integration"
        description={`Boost client data management by integrating Synkli with Xero. Sync financial info instantly, update data digitally, and streamline your accounting practice management.`}
      />

      <FeatureDetailsPage data={connectToXeroPage} />
      
    </Layout>
  )
}

export default ConnectToXero
